.color-label{
    color: white !important;
}

.logo-mef{
    height: 2rem;
}

.logo-dgt{
    height: 5rem;
}

.home-background {
    background: url('../../public/img/background.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.home-page-content {
    position: relative;
    color: white;
}

.info-container {
    grid-column: 1 / 4;
    grid-row: 1;
    z-index: 100;
    color: white;
}
.card-button-container {
    grid-column: 1 / 4;
    grid-row: 2;
}

.info-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 1rem;
}

.card-title-custom {
    text-align: left;
    font: normal normal 16px/18px Titillium Web;
    letter-spacing: 1.07px;
    color: #192864;
    text-transform: uppercase;
    opacity: 1;
    background-color: #FFFFFF;
}

.info-header-title{
    font: normal normal 16px / 18px Titillium Web;
    letter-spacing: 1.07px;
    color: #093697;
}

.card-text-custom {
    text-align: left;
    font: normal normal normal 18px/24px Titillium Web;
    letter-spacing: 0px;
    color: #192864;
    opacity: 1;
}

.numero-sentenze {
    text-align: left;
    font: normal normal normal 3rem Titillium Web;
    letter-spacing: 0px;
    color: #2D6CB4;
    opacity: 1;
}

.custom-button{
    text-align: left;
    font: normal normal bold 14px/21px Titillium Web;
    letter-spacing: 1px;
    color: #093697;
    text-transform: uppercase;
    opacity: 1 !important;
    background-color: white ;
}

.logo-mef-footer{
    height: 4.5rem;
}

.logo-partner-footer{
    height: 3.8rem;
}

.margin-class{
    margin-left: -0.5rem !important;
}

.font-title-footer{
    font-size: 0.75em;
    font-weight: 400;
}

.display-flex{
    display: flex;
}

.display-none{
    display: none;
}

.color-black{
    color:black !important;
}
.color-grey{
    color:grey !important;
}

.footer-color {
    background-color:#192864 !important
}

.label-esito{
    color:black !important;
    word-wrap: break-word !important;
    width: 100% !important;
}

.text-align-center{
    text-align: center;
}

.width-all{
    width: 100%;
}

.display-end{
    display: flex;
    justify-content: flex-end;
}

.viewer-container-100{
    background-color: black;
    width: 100%;
    overflow: hidden; 
    height: 100vh
}

.viewer-container-110{
    background-color: black;
    width: 100%;
    overflow: hidden; 
    height: 110vh;
}

.link-color{
    color: black;
}

.width-all{
    width: 100%;
}

.label-matter{
    margin-left: -1vw;
    word-wrap: break-word; 
    width: 100%;
}

.opacity-class{
    opacity: 0.7;
}

.opacity-class-unset{
    opacity: 1;
}

.display-class{
    display: block;
}

.display-class-unset{
    display: none;
}

.custom-disable-pagelink{
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    text-decoration: none;
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: rgba(128, 128, 128, 0.70);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color);
}

.absolute-content{
    position: absolute;
}

.no-border{
    box-sizing: unset;
    border: none;
}


.info-cursor {
    cursor: pointer;
}

@media (width <=576px) {
    .custom-padding-cards {
        padding-top: 1.5rem;
    }
    .custom-padding-footer-2-cards {
        padding-top: 20rem !important;
    }
    .custom-padding-footer-1-cards {
        padding-top: 10rem !important;
    }

    .home-background {
        background: url('../../public/img/background_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        
    }
}
  
.main-header {
    border-bottom: 1px solid #093697;
}

@media (width <=768px) {
    .custom-padding-footer-2-cards {
        padding-top: 20rem !important;
    }
    .custom-padding-footer-1-cards {
        padding-top: 10rem !important;
    }
    .custom-margin-footer-images {
        margin-top: 1rem !important;
    }
}

@media (width < 992px) {
    .custom-padding-footer-2-cards {
        padding-top: 20rem !important;
    }
    .custom-padding-footer-1-cards {
        padding-top: 10rem !important;
    }
}
@media (992px <= width < 1280px) {
    .custom-padding-images-footer {
        padding-top: 4rem !important;
    }
}

.table-responsive{
    overflow-y: hidden !important;
}
