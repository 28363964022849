.spinner-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20vw;
}

.spinner-container-search{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px
}